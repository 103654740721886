import React from "react"
import EventLayout from "../../components/layouts/eventLayout"

import Chamber from "../../images/NDPFlyer.jpg"

const PrayerIndexPage = props => (
  <EventLayout pageTitle="National Day of Prayer">
    <h2>May 1, 2025 – Information Coming Soon</h2>
     {/*--   <img
        src={Chamber}
        css={{
          border: "1px solid #000",
          width: "100%",
          marginRight: "1rem",
          marginBottom: `1rem`,
        }}
      />
      -- */}
  </EventLayout>
)

export default PrayerIndexPage
